import React from 'react';
import Loadable from 'react-loadable';

import { PATH } from 'app/constants/path';
import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import { Route } from 'react-router';

const loading = <div>loading ...</div>;

const MaterialProposal = Loadable({
    loader: () => import(/* webpackChunkName: "logistic-material-proposal-list" */ 'app/modules/logistics-material-proposal/logistics-material-proposal'),
    loading: () => loading,
});

const MaterialProposalChangeLogs = Loadable({
    loader: () => import(/* webpackChunkName: "logistic-material-proposal-change-logs-list" */ 'app/modules/logistics-material-proposal/logistics-material-proposal-change-logs'),
    loading: () => loading,
});


const Suppliers = Loadable({
  loader: () => import(/* webpackChunkName: "logistic-suppliers" */ 'app/modules/suppliers/suppliers'),
  loading: () => loading,
});

const LogisticsRoutes = () => (
    <ErrorBoundaryRoutes>
        <Route path={PATH.LOGISTICS}>
            <Route path={PATH.MATERIAL_PROPOSAL} element={<MaterialProposal />} />
            <Route path={PATH.MATERIAL_PROPOSAL_CHANGE_LOGS} element={<MaterialProposalChangeLogs />} />
            <Route path={PATH.SUPPLIERS} element={<Suppliers />} />
        </Route>
    </ErrorBoundaryRoutes>
);

export default LogisticsRoutes;
