import { PATH } from 'app/constants/path';
import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import React from 'react'
import { Route } from 'react-router';
import Loadable from 'react-loadable';
const loading = <div>loading ...</div>;
const IncomingInvoices = Loadable({
  loader: () => import(/* webpackChunkName: "employees" */ 'app/modules/incoming-invoice/index'),
  loading: () => loading,
});
const IncomingInvoicesRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route path={PATH.INCOMING_INVOICE}>
      <Route index path={""} element={<IncomingInvoices/>}></Route>

    </Route>
  </ErrorBoundaryRoutes>
);

export default IncomingInvoicesRoutes;
