import './card.scss';
import React from 'react';
import { Card as CardStrap, CardBody, CardHeader, CardProps } from 'reactstrap';

interface ICard extends CardProps {
  children: React.ReactNode;
  header?: React.ReactNode;
}

const Card = (props: ICard) => {
  const { header, children, className, ...rest } = props;

  return (
    <CardStrap {...rest}>
      {header && <CardHeader>{header}</CardHeader>}
      <CardBody className={className}>{children}</CardBody>
    </CardStrap>
  );
};

export default Card;
