export const QUERY_KEY = {
  FILE: 'FILE',
  TIME_KEEPING: 'TIME_KEEPING',
  TIME_KEEPING_OFF_TRACKING: 'TIME_KEEPING_OFF_TRACKING',
  TIME_KEEPING_RECORD: 'TIME_KEEPING_RECORD',
  TIME_KEEPING_EXPORT: 'TIME_KEEPING_EXPORT',
  TIME_KEEPING_EXPORT_BULK: 'TIME_KEEPING_EXPORT_BULK',
  TIME_KEEPING_VIOLATION: 'TIME_KEEPING_VIOLATION',
  TIME_KEEPING_EXPLANATION: 'TIME_KEEPING_EXPLANATION',
  TIME_KEEPING_EXPLANATION_RECORD: 'TIME_KEEPING_EXPLANATION_RECORD',
  TIME_KEEPING_MONTHLY: 'TIME_KEEPING_MONTHLY',
  TIME_KEEPING_MONTHLY_DETAIL: 'TIME_KEEPING_MONTHLY_DETAIL',
  TIME_KEEPING_MONTHLY_EXPORT: 'TIME_KEEPING_MONTHLY_EXPORT',
  LEAVE_REQUEST: 'LEAVE_REQUEST',
  PRODUCTION_STANDARD: 'PRODUCTION_STANDARD',
  PRODUCTION_STANDARDS: 'PRODUCTION_STANDARDS',
  PRODUCTION_STANDARDS_MO: 'PRODUCTION_STANDARDS_MO',
  PRODUCTION_COMMANDS: 'PRODUCTION_COMMAND',
  PRODUCTION_COMMAND_WO: 'PRODUCTION_COMMAND_WO',
  PRODUCTION_COMMAND_BY_ID: 'PRODUCTION_COMMAND_BY_ID',
  PRODUCTION_PROCESSES: 'PRODUCTION_PROCESSES',
  PRODUCTION_PROCESS: 'PRODUCTION_PROCESS',
  PRODUCTION_QUALITIES: 'PRODUCTION_QUALITIES',
  PRODUCTION_QUALITY: 'PRODUCTION_QUALITY',
  PRODUCTION_WORK_CENTERS: 'PRODUCTION_WORK_CENTERS',
  PRODUCTION_ROUTINGS: 'PRODUCTION_ROUTINGS',
  PRODUCTION_MAINTAINS: 'PRODUCTION_MAINTAINS',
  PRODUCTION_PACKAGES: 'PRODUCTION_PACKAGES',
  FACTORIES: 'FACTORIES',
  STORAGES: 'STORAGES',
  RECEIVE_MATERIAL_CHECKLIST: 'RECEIVE_MATERIAL_CHECKLIST',
  STEAMING_PROCESS_CHECKLIST: 'STEAMING_PROCESS_CHECKLIST',
  ADDITIVE_MATERIAL_CHECKLIST: 'ADDITIVE_MATERIAL_CHECKLIST',
  METAL_DETECTION_CHECKLIST: 'METAL_DETECTION_CHECKLIST',
  MIXING_REPORT_CHECKLIST: 'MIXING_REPORT_CHECKLIST',
  MACHINE_OPERATION_CHECKLIST: 'MACHINE_OPERATION_CHECKLIST',
  EMPLOYEE: 'EMPLOYEE',
  CUSTOMERS: 'CUSTOMERS',
  CUSTOMER: 'CUSTOMER',
  CUSTOMER_ENABLED_EXPORT: 'CUSTOMER_ENABLED_EXPORT',
  CUSTOMER_DISABLED_EXPORT: 'CUSTOMER_DISABLED_EXPORT',
  NEXT_CUSTOMER_CODE: 'CUSTOMER_NEXT_CODE',
  ENABLED_CUSTOMERS: 'ENABLED_CUSTOMERS',
  DISABLED_CUSTOMERS: 'DISABLED_CUSTOMERS',
  CUSTOMER_BIRTHDAY: 'CUSTOMER_BIRTHDAY',
  CONTRACTS: 'CONTRACTS',
  CONTRACT: 'CONTRACT',
  CONTRACTS_DELETED: 'CONTRACTS_DELETED',
  CONTRACT_PRODUCTS: 'CONTRACT_PRODUCTS',
  CONTRACT_MATERIALS: 'CONTRACT_MATERIALS',
  CONTRACT_FILES: 'CONTRACT_FILES',
  ORDERS: 'ORDERS',
  ORDER: 'ORDER',
  ORDER_EXPORT: 'ORDER_EXPORT',
  ORDER_REVIEW: 'ORDER_REVIEW',
  ORDER_WITH_MANUFACTURE: 'ORDER_WITH_MANUFACTURE',
  PURCHASES: 'PURCHASES',
  PURCHASE: 'PURCHASE',
  PURCHASE_REVIEW: 'PURCHASE_REVIEW',
  PURCHASE_REQUEST_FILE: 'PURCHASE_REQUEST_FILE',
  QUOTATIONS: 'QUOTATIONS',
  QUOTATIONS_EXPORT: 'QUOTATIONS_EXPORT',
  QUOTATION_DETAILS: 'QUOTATION_DETAILS',
  QUOTATION: 'QUOTATION',
  WORKSPACES: 'WORKSPACES',
  ANNUAL_LEAVES: 'ANNUAL_LEAVES',
  ANNUAL_LEAVES_EMPLOYEE: 'ANNUAL_LEAVES_EMPLOYEE',
  RECRUITMENTS: 'RECRUITMENTS',
  RECRUITMENTS_HISTORY: 'RECRUITMENTS_HISTORY',
  RECRUITMENTS_CANDIDATES: 'RECRUITMENTS_CANDIDATES',
  RECRUITMENTS_CANDIDATE: 'RECRUITMENTS_CANDIDATE',
  EMPLOYEE_PROFILES: 'EMPLOYEE_PROFILES',
  EMPLOYEE_PROFILE: 'EMPLOYEE_PROFILE',
  EMPLOYEE_SEQUENCE_ID: 'EMPLOYEE_SEQUENCE_ID',
  EMPLOYEE_TAX_CODE: 'EMPLOYEE_TAX_CODE',
  EMPLOYEE_CITIZEN_ID: 'EMPLOYEE_CITIZEN_ID',
  EMPLOYEE_BANK_NUMBER: 'EMPLOYEE_BANK_NUMBER',
  EMPLOYEE_EXPORT: 'EMPLOYEE_EXPORT',
  EMPLOYEE_EXPORT_CONTRACT: 'EMPLOYEE_EXPORT_CONTRACT',
  EMPLOYEE_CHANGE_LOGS: 'EMPLOYEE_CHANGE_LOGS',
  EMPLOYEE_CHANGE_LOG: 'EMPLOYEE_CHANGE_LOG',
  EMPLOYEE_XLSX_TEMPLATE: 'EMPLOYEE_XLSX_TEMPLATE',
  EMPLOYEE_LIST_DEPARTMENTS: 'EMPLOYEE_LIST_DEPARTMENTS',
  EMPLOYEE_SENIORITY: 'EMPLOYEE_SENIORITY',
  LEAVE_REGIMES: 'LEAVE_REGIMES',
  LEAVE_REGIME: 'LEAVE_REGIME',
  DOCUMENTARIES: 'DOCUMENTARIES',
  DOCUMENTARY: 'DOCUMENTARY',
  REPORTS_UNIFORM_EXPIRING: 'REPORTS_UNIFORM_EXPIRING',
  REPORTS_UNIFORM_IMPORT: 'REPORTS_UNIFORM_IMPORT',
  REPORTS_UNIFORM_EXPORT: 'REPORTS_UNIFORM_EXPORT',
  REPORTS_UNIFORM_EXPORT_EXCEL: 'REPORTS_UNIFORM_EXPORT_EXCEL',
  REPORTS_UNIFORM_INVENTORY: 'REPORTS_UNIFORM_INVENTORY',
  REPORTS_UNIFORM_SUPPORT: 'REPORTS_UNIFORM_SUPPORT',
  REPORTS_UNIFORM_ALL: 'REPORTS_UNIFORM_ALL',
  REPORTS_UNIFORM_CHANGE: 'REPORTS_UNIFORM_CHANGE',
  REPORTS_EMPLOYEE_EXPIRING_CONTRACT: 'REPORTS_EMPLOYEE_EXPIRING_CONTRACT',
  REPORTS_EMPLOYEE_EXPIRING_CONTRACT_EXCEL: 'REPORTS_EMPLOYEE_EXPIRING_CONTRACT_EXCEL',
  REPORTS_LEAVE_REGIME: 'REPORTS_LEAVE_REGIME',
  REPORTS_LEAVE_REGIME_EXCEL: 'REPORTS_LEAVE_REGIME_EXCEL',
  REPORTS_RECRUITMENT: 'REPORTS_RECRUITMENT',
  REPORTS_RECRUITMENT_EXCEL: 'REPORTS_RECRUITMENT_EXCEL',
  REPORTS_HR_CHANGE: 'REPORTS_HR_CHANGE',
  UNIFORMS: 'UNIFORMS',
  UNIFORM: 'UNIFORM',
  UNIFORM_ORDERS: 'UNIFORM_ORDERS',
  UNIFORM_ORDER: 'UNIFORM_ORDER',
  UNIFORM_RELEASES: 'UNIFORM_RELEASES',
  UNIFORM_RELEASE: 'UNIFORM_RELEASE',
  UNIFORM_STOCKS: 'UNIFORM_STOCKS',
  UNIFORM_RETURN_REMAINING: 'UNIFORM_RETURN_REMAINING',
  NOTIFICATIONS: 'NOTIFICATIONS',
  COUNT_UNREAD_NOTIFICATIONS: 'COUNT_UNREAD_NOTIFICATIONS',
  LEAVE_TRACKING: 'LEAVE_TRACKING',
  UOMS: 'UOMS',
  UOM: 'UOM',
  UOM_GROUPS: 'UOM_GROUPS',
  UOM_GROUP: 'UOM_GROUP',
  SUPPLIERS: 'SUPPLIERS',
  SUPPLIER: 'SUPPLIER',
  WAREHOUSES: 'WAREHOUSES',
  WAREHOUSE: 'WAREHOUSE',
  WAREHOUSE_ROUTING: ' WAREHOUSE_ROUTING',
  WAREHOUSE_TYPES: 'WAREHOUSE_TYPES',
  WAREHOUSE_TYPE: 'WAREHOUSE_TYPE',
  AUTHORITIES: 'AUTHORITIES',
  GROUPS: 'GROUPS',
  GROUP: 'GROUP',
  INVENTORY_MATERIALS: 'INVENTORY_MATERIALS',
  INCOMING_INVOICES: 'INCOMING_INVOICES',
  NEXT_INCOMING_INVOICE_CODE: 'NEXT_INCOMING_INVOICE_CODE',
  ITEMS: 'ITEMS',
  ITEM_CATEGORY: 'ITEM_CATEGORY',
  ACCOUNT_STATUSES: 'ACCOUNT_STATUSES',
  ACCOUNT_BY_ID: 'ACCOUNT_BY_ID',
};

export const MUTATION_KEY = {
  PRODUCTION_COMMANDS_MAINTAIN: ' PRODUCTION_COMMANDS_MAINTAIN',
  READ_NOTIFICATION: 'READ_NOTIFICATION',
  UPDATE_TIME_KEEPING: 'UPDATE_TIME_KEEPING',
  UPDATE_TIME_KEEPING_RECORD: 'UPDATE_TIME_KEEPING_RECORD',
  CREATE_TIME_KEEPING_EXPLANATION: 'CREATE_TIME_KEEPING_EXPLANATION',
  UPDATE_TIME_KEEPING_EXPLANATION: 'UPDATE_TIME_KEEPING_EXPLANATION',
  CANCEL_TIME_KEEPING_EXPLANATION: 'CANCEL_TIME_KEEPING_EXPLANATION',
  DELETE_TIME_KEEPING_EXPLANATION: 'DELETE_TIME_KEEPING_EXPLANATION',
  REVIEW_TIME_KEEPING_EXPLANATION: 'REVIEW_TIME_KEEPING_EXPLANATION',
  REVIEW_TIME_KEEPING_MONTHLY: 'REVIEW_TIME_KEEPING_MONTHLY',
  APPROVE_TIME_KEEPING_MONTHLY: 'APPROVE_TIME_KEEPING_MONTHLY',
  REJECT_TIME_KEEPING_MONTHLY: 'REJECT_TIME_KEEPING_MONTHLY',
  CREATE_LEAVE_REQUEST: 'CREATE_LEAVE_REQUEST',
  CANCEL_LEAVE_REQUEST: 'CANCEL_LEAVE_REQUEST',
  DELETE_LEAVE_REQUEST: 'DELETE_LEAVE_REQUEST',
  REVIEW_LEAVE_REQUEST: 'REVIEW_LEAVE_REQUEST',
  CREATE_PRODUCTION_STANDARD: 'CREATE_PRODUCTION_STANDARD',
  DELETE_PRODUCTION_STANDARD: 'DELETE_PRODUCTION_STANDARD',
  DISPOSE_PRODUCTION_STANDARD: 'DISPOSE_PRODUCTION_STANDARD',
  UPDATE_PRODUCTION_STANDARD: 'UPDATE_PRODUCTION_STANDARD',
  CREATE_PRODUCTION_COMMAND: 'CREATE_PRODUCTION_COMMAND',
  UPDATE_PRODUCTION_COMMAND: 'UPDATE_PRODUCTION_COMMAND',
  DELETE_PRODUCTION_COMMAND: 'DELETE_PRODUCTION_COMMAND',
  CANCEL_PRODUCTION_COMMAND: 'CANCEL_PRODUCTION_COMMAND',
  START_PRODUCTION_COMMAND: 'START_PRODUCTION_COMMAND',
  CREATE_PRODUCTION_PROCESS: 'CREATE_PRODUCTION_PROCESS',
  UPDATE_PRODUCTION_PROCESS: 'UPDATE_PRODUCTION_PROCESS',
  START_PRODUCTION_PROCESS: 'START_PRODUCTION_PROCESS',
  STOP_PRODUCTION_PROCESS: 'STOP_PRODUCTION_PROCESS',
  COMPLETE_PRODUCTION_PROCESS: 'COMPLETE_PRODUCTION_PROCESS',
  DELETE_PRODUCTION_PROCESS: 'DELETE_PRODUCTION_PROCESS',
  CREATE_MATERIAL_RECEIPT_MONITORING: 'CREATE_MATERIAL_RECEIPT_MONITORING',
  UPDATE_MATERIAL_RECEIPT_MONITORING: 'UPDATE_MATERIAL_RECEIPT_MONITORING',
  DELETE_MATERIAL_RECEIPT_MONITORING: 'DELETE_MATERIAL_RECEIPT_MONITORING',
  CREATE_SELECTING_ADDING_ADDITIVES: 'CREATE_SELECTING_ADDING_ADDITIVES',
  UPDATE_SELECTING_ADDING_ADDITIVES: 'UPDATE_SELECTING_ADDING_ADDITIVES',
  DELETE_SELECTING_ADDING_ADDITIVES: 'DELETE_SELECTING_ADDING_ADDITIVES',
  CREATE_MACHINE_OPERATION_MONITORING: 'CREATE_MACHINE_OPERATION_MONITORING',
  UPDATE_MACHINE_OPERATION_MONITORING: 'UPDATE_MACHINE_OPERATION_MONITORING',
  DELETE_MACHINE_OPERATION_MONITORING: 'DELETE_MACHINE_OPERATION_MONITORING',
  CREATE_MONITORING_STEAMING_DRYING: 'CREATE_MONITORING_STEAMING_DRYING',
  UPDATE_MONITORING_STEAMING_DRYING: 'UPDATE_MONITORING_STEAMING_DRYING',
  DELETE_MONITORING_STEAMING_DRYING: 'DELETE_MONITORING_STEAMING_DRYING',
  CREATE_MAGNET_MESH_TEST: 'CREATE_MAGNET_MESH_TEST',
  UPDATE_MAGNET_MESH_TEST: 'UPDATE_MAGNET_MESH_TEST',
  DELETE_MAGNET_MESH_TEST: 'DELETE_MAGNET_MESH_TEST',
  CREATE_MIXING_REPORT: 'CREATE_MIXING_REPORT',
  UPDATE_MIXING_REPORT: 'UPDATE_MIXING_REPORT',
  DELETE_MIXING_REPORT: 'DELETE_MIXING_REPORT',
  CREATE_QUALITY_SAMP_REVIEWS: 'CREATE_QUALITY_SAMP_REVIEWS',
  UPDATE_QUALITY_SAMP_REVIEWS: 'UPDATE_QUALITY_SAMP_REVIEWS',
  UPDATE_QUALITY_SAMP_REVIEWS_PASS: 'UPDATE_QUALITY_SAMP_REVIEWS_PASS',
  DELETE_QUALITY_SAMP_REVIEWS: 'DELETE_QUALITY_SAMP_REVIEWS',
  CREATE_QUALITY_DISPOSAL: 'CREATE_QUALITY_DISPOSAL',
  REVIEW_QUALITY_DISPOSAL: 'REVIEW_QUALITY_DISPOSAL',
  CREATE_WORK_CENTER: 'CREATE_WORK_CENTER',
  UPDATE_WORK_CENTER: 'UPDATE_WORK_CENTER',
  DELETE_WORK_CENTER: 'DELETE_WORK_CENTER',
  CREATE_PRODUCTION_ROUTING: 'CREATE_PRODUCTION_ROUTING',
  UPDATE_PRODUCTION_ROUTING: 'UPDATE_PRODUCTION_ROUTING',
  DELETE_PRODUCTION_ROUTING: 'DELETE_PRODUCTION_ROUTING',
  CREATE_PRODUCTION_MAINTAIN: 'CREATE_PRODUCTION_MAINTAIN',
  UPDATE_PRODUCTION_MAINTAIN: 'UPDATE_PRODUCTION_MAINTAIN',
  DELETE_PRODUCTION_MAINTAIN: 'DELETE_PRODUCTION_MAINTAIN',
  CREATE_PRODUCTION_PACKAGE: 'CREATE_PRODUCTION_PACKAGE',
  UPDATE_PRODUCTION_PACKAGE: 'UPDATE_PRODUCTION_PACKAGE',
  DELETE_PRODUCTION_PACKAGE: 'DELETE_PRODUCTION_PACKAGE',
  CREATE_CUSTOMER: 'CREATE_CUSTOMER',
  UPDATE_CUSTOMER: 'UPDATE_CUSTOMER',
  DISABLE_CUSTOMER: 'DISABLE_CUSTOMER',
  ACTIVATE_CUSTOMER: 'ACTIVATE_CUSTOMER',
  TRANSFER_CUSTOMER: 'TRANSFER_CUSTOMER',
  DELETE_CUSTOMER: 'DELETE_CUSTOMER',
  CREATE_CONTRACT: 'CREATE_CONTRACT',
  UPDATE_CONTRACT: 'UPDATE_CONTRACT',
  DELETE_CONTRACT: 'DELETE_CONTRACT',
  RECOVER_CONTRACT: 'RECOVER_CONTRACT',
  APPROVED_REVIEW_CONTRACT: 'APPROVED_REVIEW_CONTRACT',
  APPROVE_CONTRACT: 'APPROVE_CONTRACT',
  REVIEW_LIQUID_CONTRACT: 'REVIEW_LIQUID_CONTRACT',
  CONSENT_LIQUID_CONTRACT: 'CONSENT_LIQUID_CONTRACT',
  REFUSE_LIQUID_CONTRACT: 'REFUSE_LIQUID_CONTRACT',
  CREATE_ORDER: 'CREATE_ORDER',
  UPDATE_ORDER: 'UPDATE_ORDER',
  DELETE_ORDER: 'DELETE_ORDER',
  CREATE_ORDER_REVIEW: 'CREATE_ORDER_REVIEW',
  UPDATE_ORDER_REVIEW: 'UPDATE_ORDER_REVIEW',
  CREATE_PURCHASE: 'CREATE_PURCHASE',
  UPDATE_PURCHASE: 'UPDATE_PURCHASE',
  DELETE_PURCHASE: 'DELETE_PURCHASE',
  UPDATE_PURCHASE_DELIVERY: 'UPDATE_PURCHASE_DELIVERY',
  CREATE_PURCHASE_REVIEW: 'CREATE_PURCHASE_REVIEW',
  UPDATE_PURCHASE_REVIEW: 'UPDATE_PURCHASE_REVIEW',
  CREATE_QUOTATION: 'CREATE_QUOTATION',
  CREATE_QUOTATION_DETAILS: 'CREATE_QUOTATION_DETAILS',
  UPDATE_QUOTATION_DETAILS: 'UPDATE_QUOTATION_DETAILS',
  DELETE_QUOTATION_DETAILS: 'DELETE_QUOTATION_DETAILS',
  UPDATE_QUOTATION: 'UPDATE_QUOTATION',
  DELETE_QUOTATION: 'DELETE_QUOTATION',
  INTERNAL_SEND_QUOTATION: 'INTERNAL_SEND_QUOTATION',
  CANCEL_QUOTATION: 'CANCEL_QUOTATION',
  CUSTOMER_PROCESS_QUOTATION: 'CUSTOMER_PROCESS_QUOTATION',
  CUSTOMER_SEND_QUOTATION: 'CUSTOMER_SEND_QUOTATION',
  INTERNAL_APPROVE_QUOTATION: 'INTERNAL_APPROVE_QUOTATION',
  INTERNAL_REJECT_QUOTATION: 'INTERNAL_REJECT_QUOTATION',
  CREATE_WORKSPACE: 'CREATE_WORKSPACE',
  UPDATE_WORKSPACE: 'UPDATE_WORKSPACE',
  DELETE_WORKSPACE: 'DELETE_WORKSPACE',
  PATCH_ANNUAL_LEAVE: 'PATCH_ANNUAL_LEAVE',
  CREATE_RECRUITMENT: 'CREATE_RECRUITMENT',
  UPDATE_RECRUITMENT: 'UPDATE_RECRUITMENT',
  DELETE_RECRUITMENT: 'DELETE_RECRUITMENT',
  APPROVE_RECRUITMENT: 'APPROVE_RECRUITMENT',
  REJECT_RECRUITMENT: 'REJECT_RECRUITMENT',
  UPDATE_RECRUITMENT_ADJOURN: 'UPDATE_RECRUITMENT_ADJOURN',
  CREATE_INTERVIEW: 'CREATE_INTERVIEW',
  UPDATE_INTERVIEW_RESULT: 'UPDATE_INTERVIEW_RESULT',
  CREATE_EMPLOYEE_PROFILES: 'CREATE_EMPLOYEE_PROFILES',
  UPDATE_EMPLOYEE_PROFILES: 'UPDATE_EMPLOYEE_PROFILES',
  ENABLE_EMPLOYEE_PROFILES: 'ENABLE_EMPLOYEE_PROFILES',
  DISABLE_EMPLOYEE_PROFILES: 'DISABLE_EMPLOYEE_PROFILES',
  PATCH_CONFIRM_LEAVE: 'PATCH_CONFIRM_LEAVE',
  CREATE_LEAVE_REGIME: 'CREATE_LEAVE_REGIME',
  UPDATE_LEAVE_REGIME: 'UPDATE_LEAVE_REGIME',
  DELETE_LEAVE_REGIME: 'DELETE_LEAVE_REGIME',
  CANCEL_LEAVE_REGIME: 'CANCEL_LEAVE_REGIME',
  PROCESS_LEAVE_REGIME: 'PROCESS_LEAVE_REGIME',
  REVIEW_LEAVE_REGIME: 'REVIEW_LEAVE_REGIME',
  CREATE_DOCUMENTARY: 'CREATE_DOCUMENTARY',
  UPDATE_DOCUMENTARY: 'UPDATE_DOCUMENTARY',
  DELETE_DOCUMENTARY: 'DELETE_DOCUMENTARY',
  CREATE_DOCUMENTARY_REVIEW: 'CREATE_DOCUMENTARY_REVIEW',
  APPROVE_DOCUMENTARY_REVIEW: 'APPROVE_DOCUMENTARY_REVIEW',
  REFUSE_DOCUMENTARY_REVIEW: 'REFUSE_DOCUMENTARY_REVIEW',
  CREATE_UNIFORM: 'CREATE_UNIFORM',
  UPDATE_UNIFORM: 'UPDATE_UNIFORM',
  DELETE_UNIFORM: 'DELETE_UNIFORM',
  CREATE_UNIFORM_ORDER: 'CREATE_UNIFORM_ORDER',
  UPDATE_UNIFORM_ORDER: 'UPDATE_UNIFORM_ORDER',
  DELETE_UNIFORM_ORDER: 'DELETE_UNIFORM_ORDER',
  CANCEL_UNIFORM_ORDER: 'CANCEL_UNIFORM_ORDER',
  CREATE_UNIFORM_ORDER_STOCK: 'CREATE_UNIFORM_ORDER_STOCK',
  PROCESS_UNIFORM_ORDER: 'PROCESS_UNIFORM_ORDER',
  PATCH_UNIFORM_STOCK: 'PATCH_UNIFORM_STOCK',
  CREATE_UNIFORM_RELEASE: 'CREATE_UNIFORM_RELEASE',
  CREATE_UNIFORM_RETURN: 'CREATE_UNIFORM_RETURN',
  EXPORT_UNIFORM_STOCK_IN: 'EXPORT_UNIFORM_STOCK_IN',
  EXPORT_UNIFORM_STOCK_OUT: 'EXPORT_UNIFORM_STOCK_OUT',
  CREATE_UOM: 'CREATE_UOM',
  UPDATE_UOM: 'UPDATE_UOM',
  DELETE_UOM: 'DELETE_UOM',
  CREATE_UOM_GROUP: 'CREATE_UOM_GROUP',
  UPDATE_UOM_GROUP: 'UPDATE_UOM_GROUP',
  DELETE_UOM_GROUP: 'DELETE_UOM_GROUP',
  CREATE_SUPPLIER: 'CREATE_SUPPLIER',
  UPDATE_SUPPLIER: 'UPDATE_SUPPLIER',
  DELETE_SUPPLIER: 'DELETE_SUPPLIER',
  CREATE_WAREHOUSE: 'CREATE_WAREHOUSE',
  UPDATE_WAREHOUSE: 'UPDATE_WAREHOUSE',
  DELETE_WAREHOUSE: 'DELETE_WAREHOUSE',
  CREATE_WAREHOUSE_TYPE: 'CREATE_WAREHOUSE_TYPE',
  UPDATE_WAREHOUSE_TYPE: 'UPDATE_WAREHOUSE_TYPE',
  DELETE_WAREHOUSE_TYPE: 'DELETE_WAREHOUSE_TYPE',
  CREATE_GROUP: 'CREATE_GROUP',
  UPDATE_GROUP: 'UPDATE_GROUP',
  DELETE_GROUP: 'DELETE_GROUP',
  ADD_GROUP_USERS: 'ADD_GROUP_USERS',
  CREATE_INCOMING_INVOICE: 'CREATE_INCOMING_INVOICE',
  UPDATE_INCOMING_INVOICE: 'UPDATE_INCOMING_INVOICE',
  DELETE_INCOMING_INVOICE: 'DELETE_INCOMING_INVOICE',
  CREATE_ITEM: 'CREATE_ITEM',
  UPDATE_ITEM: 'UPDATE_ITEM',
  DELETE_ITEM: 'DELETE_ITEM',
  CREATE_EMPLOYEE_ACCOUNT: 'CREATE_EMPLOYEE_ACCOUNT',
  TOGGLE_ACTIVATE_ACCOUNT: 'TOGGLE_ACTIVATE_ACCOUNT',
};
