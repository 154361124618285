import React from 'react';

import './menu-item.scss';
import { useToggleMenu } from 'app/hooks/use-menu';
import { useLocation, useNavigate } from 'react-router';

export interface ISubMenuItem {
  label: string;
  to?: string;
  key?: string;
  items?: ISubMenuItem[];
  isPermitted?: boolean;
}

const SubMenuChildItem = (props: ISubMenuItem) => {
  const { label, to, isPermitted } = props;

  const { toggleSelectedKeys } = useToggleMenu([]);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  // const splitPath = to.split(':id');
  // const joinPath = splitPath.join('/');

  return (
    <>
      {isPermitted && (
        <div
          onClick={e => {
            e.stopPropagation();
            toggleSelectedKeys(to);
            to && navigate(to);
          }}
          className={`sub-menu-child-item ${pathname === to ? 'active' : ''}`}
        >
          <div className="label label-child">{label}</div>
        </div>
      )}
    </>
  );
};

export default SubMenuChildItem;
