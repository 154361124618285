import './modal.scss';
import React from 'react';
import { ModalBody, ModalFooter, ModalHeader, ModalProps, Modal as ModalStrap } from 'reactstrap';
import Button from '../button/button';
import { FormType } from 'app/shared/model/enumerations/form.model';

interface IModal extends ModalProps {
  isOpen: boolean;
  toggle: () => void;
  header?: React.ReactNode;
  children: React.ReactNode;
  footer?: React.ReactNode;
  onOk?: (params: any) => void;
  cancel?: boolean;
  ok?: boolean;
  onCancel?: () => void;
  okText?: string;
  cancelText?: string;
  okSubmitForm?: FormType; // Submit form type must be the same as form id to trigger submit form
  disabledOk?: boolean;
  loadingOk?: boolean;
}

const Modal = (props: IModal) => {
  const {
    isOpen,
    toggle,
    header,
    children,
    footer,
    onOk,
    cancel = true,
    ok = true,
    onCancel,
    cancelText,
    okText,
    okSubmitForm,
    backdrop = false,
    disabledOk,
    loadingOk,
    ...rest
  } = props;

  return (
    <>
      <ModalStrap {...rest} isOpen={isOpen} toggle={toggle} backdrop={backdrop}>
        <ModalHeader>
          <img src="content/images/vuesax/linear/modal-close.svg" alt="close" className="modal-close" onClick={toggle} />
        </ModalHeader>
        <ModalBody>{children}</ModalBody>
        <ModalFooter>
          {footer ? (
            footer
          ) : (
            <>
              {cancel && (
                <Button outline onClick={onCancel ? onCancel : toggle}>
                  {cancelText ? cancelText : 'Hủy'}
                </Button>
              )}
              {ok && (
                <Button
                  disabled={disabledOk}
                  loading={loadingOk !== undefined ? loadingOk : disabledOk}
                  color="primary"
                  {...(!okSubmitForm && { onClick: onOk ? onOk : toggle })}
                  form={okSubmitForm}
                >
                  {okText ? okText : 'Đồng ý'}
                </Button>
              )}
            </>
          )}
        </ModalFooter>
      </ModalStrap>
      {isOpen && <div className="modal-backdrop fade show z-0"></div>}
    </>
  );
};

export default Modal;
