import React from 'react';
import Loadable from 'react-loadable';
import { Route } from 'react-router';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import { PATH } from 'app/constants/path';

const loading = <div>loading ...</div>;

const VoucherRequestPayment = Loadable({
  loader: () => import(/* webpackChunkName: "voucher-request-payment-list" */ 'app/modules/voucher-request-payment/voucher-request-payment'),
  loading: () => loading,
});

const VoucherRequestOfAdvance = Loadable({
  loader: () => import(/* webpackChunkName: "voucher-request-of-advance-list" */ 'app/modules/voucher-request-of-advance/voucher-request-of-advance'),
  loading: () => loading,
});

const VoucherAdvanceRepayment = Loadable({
  loader: () => import(/* webpackChunkName: "voucher-advance-repayment-list" */ 'app/modules/voucher-advance-repayment/voucher-advance-repayment'),
  loading: () => loading,
});

const VoucherAdvancePaymentChangeLogs = Loadable({
  loader: () => import(/* webpackChunkName: "voucher-advance-payment-change-logs-list" */ 'app/modules/voucher-request-payment/voucher-request-payment-change-logs'),
  loading: () => loading,
});

const VoucherRequestOfAdvanceChangeLogs = Loadable({
  loader: () => import(/* webpackChunkName: "voucher-advance-of-advance-change-logs-list" */ 'app/modules/voucher-request-of-advance/voucher-request-of-advance-change-logs'),
  loading: () => loading,
});

const VoucherAdvanceRepaymentChangeLogs = Loadable({
  loader: () => import(/* webpackChunkName: "voucher-advance-repayment-change-logs-list" */ 'app/modules/voucher-advance-repayment/voucher-advance-repayment-change-logs'),
  loading: () => loading,
});

const VoucherListRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route path={PATH.VOUCHER}>
      <Route path={PATH.REQUEST_PAYMENT} element={<VoucherRequestPayment />} />
      <Route path={PATH.REQUEST_OF_ADVANCE} element={<VoucherRequestOfAdvance />} />
      <Route path={PATH.ADVANCE_REPAYMENT} element={<VoucherAdvanceRepayment />} />
      <Route path={PATH.REQUEST_PAYMENT_CHANGE_LOGS} element={<VoucherAdvancePaymentChangeLogs />} />
      <Route path={PATH.REQUEST_OF_ADVANCE_CHANGE_LOGS} element={<VoucherRequestOfAdvanceChangeLogs />} />
      <Route path={PATH.ADVANCE_REPAYMENT_CHANGE_LOGS} element={<VoucherAdvanceRepaymentChangeLogs />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default VoucherListRoutes;
