import { useAppSelector } from 'app/config/store';
import { PATH } from 'app/constants/path';
import { Menu } from 'app/shared/layout/menus-left/menus-left';
import { Action, PermissionResource } from 'app/shared/model/permission.model';
import { checkPermission } from 'app/shared/util/check-permission';
import React, { useState } from 'react';

export const useMenu = (): Menu[] => {
  const authorities = useAppSelector(state => state.authentication.account.authorities);
  const items = [
    {
      key: 'time-sheet',
      icon: <img src="content/images/vuesax/bulk/task-square.svg" alt="time-sheet" />,
      selectedIcon: <img src="content/images/vuesax/bulk/task-square-selected.svg" alt="time-sheet" />,
      label: 'Bảng chấm công',
      isPermitted:
        checkPermission(authorities, PermissionResource.TIME_KEEPING, Action.READ) ||
        checkPermission(authorities, PermissionResource.TIME_KEEPING_VIOLATION, Action.READ) ||
        checkPermission(authorities, PermissionResource.TIME_KEEPING_EXPLANATION, Action.READ) ||
        checkPermission(authorities, PermissionResource.PERSONAL_MONTHLY_TIMESHEET, Action.READ),
      items: [
        {
          label: 'Chấm công',
          to: PATH.TIME_SHEET,
          key: PATH.TIME_SHEET,
          isPermitted: checkPermission(authorities, PermissionResource.TIME_KEEPING, Action.READ),
        },
        {
          label: 'Vi phạm chấm công',
          key: 'violation',
          isPermitted:
            checkPermission(authorities, PermissionResource.TIME_KEEPING_VIOLATION, Action.READ) ||
            checkPermission(authorities, PermissionResource.TIME_KEEPING_EXPLANATION, Action.READ),
          items: [
            {
              label: 'Vi phạm chấm công',
              to: PATH.TIME_SHEET_VIOLATION,
              key: PATH.TIME_SHEET_VIOLATION,
              isPermitted: checkPermission(authorities, PermissionResource.TIME_KEEPING_VIOLATION, Action.READ),
            },
            {
              label: 'Giải trình chấm công',
              to: PATH.TIME_SHEET_EXPLANATION,
              key: PATH.TIME_SHEET_EXPLANATION,
              isPermitted: checkPermission(authorities, PermissionResource.TIME_KEEPING_EXPLANATION, Action.READ),
            },
          ],
        },
        {
          label: 'Duyệt chấm công tháng',
          to: PATH.TIME_SHEET_BULK_APPROVAL,
          key: PATH.TIME_SHEET_BULK_APPROVAL,
          isPermitted: checkPermission(authorities, PermissionResource.PERSONAL_MONTHLY_TIMESHEET, Action.READ),
        },
      ],
    },
    {
      key: 'day-off',
      icon: <img src="content/images/vuesax/bulk/document-text.svg" alt="day-off" />,
      selectedIcon: <img src="content/images/vuesax/bulk/document-text-selected.svg" alt="day-off" />,
      label: 'Nghỉ phép',
      to: PATH.LEAVE_REQUEST,
      isPermitted: checkPermission(authorities, PermissionResource.LEAVE_REQUEST, Action.READ),
      items: [
        {
          label: 'Danh sách đơn nghỉ phép',
          to: PATH.LEAVE_REQUEST,
          key: PATH.LEAVE_REQUEST,
          isPermitted: checkPermission(authorities, PermissionResource.LEAVE_REQUEST, Action.READ),
        },
      ],
    },
    {
      key: 'production',
      icon: <img src="content/images/vuesax/bulk/building.svg" alt="production" />,
      selectedIcon: <img src="content/images/vuesax/bulk/building-selected.svg" alt="production" />,
      label: 'Sản xuất',
      isPermitted:
        checkPermission(authorities, PermissionResource.MANUFACTURE_ORDER, Action.READ) ||
        checkPermission(authorities, PermissionResource.QUALITY_CHECK_SAMPLE, Action.READ) ||
        checkPermission(authorities, PermissionResource.PRODUCT_PACKAGE, Action.READ) ||
        checkPermission(authorities, PermissionResource.PRODUCT_MAINTAIN, Action.READ) ||
        checkPermission(authorities, PermissionResource.PRODUCTION_STANDARD, Action.READ) ||
        checkPermission(authorities, PermissionResource.WORK_CENTER, Action.READ),
      items: [
        {
          label: 'Quản lý lệnh',
          to: PATH.PRODUCTION_COMMAND,
          key: PATH.PRODUCTION_COMMAND,
          isPermitted: checkPermission(authorities, PermissionResource.MANUFACTURE_ORDER, Action.READ),
        },
        {
          label: 'Quản lý công đoạn sản xuất',
          to: PATH.PRODUCTION_PROCESS,
          key: PATH.PRODUCTION_PROCESS,
          isPermitted: checkPermission(authorities, PermissionResource.MANUFACTURE_ORDER, Action.READ),
        },
        {
          label: 'Quản lý đóng gói',
          to: PATH.PRODUCTION_PACKAGES,
          key: PATH.PRODUCTION_PACKAGES,
          isPermitted: checkPermission(authorities, PermissionResource.PRODUCT_PACKAGE, Action.READ),
        },
        {
          label: 'Quản lý kiểm tra chất lượng',
          to: PATH.PRODUCTION_QUALITY,
          key: PATH.PRODUCTION_QUALITY,
          isPermitted: checkPermission(authorities, PermissionResource.QUALITY_CHECK_SAMPLE, Action.READ),
        },
        {
          label: 'Quản lý bảo trì sản phẩm',
          to: PATH.PRODUCTION_MAINTENANCE,
          key: PATH.PRODUCTION_MAINTENANCE,
          isPermitted: checkPermission(authorities, PermissionResource.PRODUCT_MAINTAIN, Action.READ),
        },
        {
          label: 'Quản lý tuyến kho sản xuất',
          to: PATH.PRODUCTION_ROUTINGS,
          key: PATH.PRODUCTION_ROUTINGS,
          isPermitted: checkPermission(authorities, PermissionResource.PRODUCT_ROUTING, Action.READ),
        },
        {
          label: 'Kế hoạch định mức',
          to: PATH.PRODUCTION_STANDARD,
          key: PATH.PRODUCTION_STANDARD,
          isPermitted: checkPermission(authorities, PermissionResource.PRODUCTION_STANDARD, Action.READ),
        },
        {
          label: 'Quản lý cụm máy sản xuất',
          to: PATH.PRODUCTION_WORK_CENTERS,
          key: PATH.PRODUCTION_WORK_CENTERS,
          isPermitted: checkPermission(authorities, PermissionResource.WORK_CENTER, Action.READ),
        },
      ],
    },
    {
      key: 'customers',
      icon: <img src="content/images/vuesax/bulk/building.svg" alt="production" />,
      selectedIcon: <img src="content/images/vuesax/bulk/building-selected.svg" alt="production" />,
      label: 'Kinh doanh',
      isPermitted:
        checkPermission(authorities, PermissionResource.CUSTOMER, Action.READ) ||
        checkPermission(authorities, PermissionResource.QUOTATION, Action.READ) ||
        checkPermission(authorities, PermissionResource.CONTRACT, Action.READ) ||
        checkPermission(authorities, PermissionResource.ORDER, Action.READ) ||
        checkPermission(authorities, PermissionResource.PURCHASE_REQUEST, Action.READ),
      items: [
        {
          label: 'Quản lý khách hàng',
          key: 'customers-management',
          isPermitted: checkPermission(authorities, PermissionResource.CUSTOMER, Action.READ),
          items: [
            {
              label: 'Danh sách khách hàng',
              to: PATH.CUSTOMERS,
              key: PATH.CUSTOMERS,
              isPermitted: checkPermission(authorities, PermissionResource.CUSTOMER, Action.READ),
            },
            {
              label: 'Danh sách vô hiệu hoá',
              to: PATH.CUSTOMERS_DISPOSED,
              key: PATH.CUSTOMERS_DISPOSED,
              isPermitted: checkPermission(authorities, PermissionResource.CUSTOMER, Action.READ),
            },
          ],
        },
        {
          label: 'Bảng báo giá',
          key: 'priceList',
          to: PATH.PRICE_LIST,
          isPermitted: checkPermission(authorities, PermissionResource.QUOTATION, Action.READ),
        },
        {
          label: 'Hợp đồng',
          key: 'contracts-management',
          isPermitted: checkPermission(authorities, PermissionResource.CONTRACT, Action.READ),
          items: [
            {
              label: 'Danh sách tất cả hợp đồng',
              to: PATH.CONTRACTS,
              key: PATH.CONTRACTS,
              isPermitted: checkPermission(authorities, PermissionResource.CONTRACT, Action.READ),
            },
            {
              label: 'Danh sách hợp đồng đã xoá',
              to: PATH.CONTRACTS_DELETED,
              key: PATH.CONTRACTS_DELETED,
              isPermitted: checkPermission(authorities, PermissionResource.CONTRACT, Action.READ),
            },
          ],
        },
        {
          label: 'Quản lý đơn đặt hàng',
          key: 'orders-management',
          isPermitted: checkPermission(authorities, PermissionResource.ORDER, Action.READ),
          items: [
            {
              label: 'Đơn đặt hàng',
              to: PATH.ORDERS,
              key: PATH.ORDERS,
              isPermitted: checkPermission(authorities, PermissionResource.ORDER, Action.READ),
            },
          ],
        },
        {
          label: 'Đề nghị thu mua',
          key: 'purchase',
          to: PATH.PURCHASE,
          isPermitted: checkPermission(authorities, PermissionResource.PURCHASE_REQUEST, Action.READ),
        },
      ],
    },
    {
      key: 'employees',
      icon: <img src="content/images/vuesax/bulk/user.svg" style={{ width: 20, height: 20 }} alt="user" />,
      selectedIcon: <img src="content/images/vuesax/bulk/user-selected.svg" alt="user-selected" />,
      label: 'HCNS',
      isPermitted:
        checkPermission(authorities, PermissionResource.EMPLOYEE, Action.READ) ||
        checkPermission(authorities, PermissionResource.WORKSPACE, Action.READ) ||
        checkPermission(authorities, PermissionResource.LEAVE_REGIME_REQUEST, Action.READ) ||
        checkPermission(authorities, PermissionResource.ANNUAL_LEAVE, Action.READ) ||
        checkPermission(authorities, PermissionResource.RECRUITMENT_REQUEST, Action.READ) ||
        checkPermission(authorities, PermissionResource.INTERVIEW_SCHEDULE, Action.READ) ||
        checkPermission(authorities, PermissionResource.DOCUMENTARY, Action.READ) ||
        checkPermission(authorities, PermissionResource.UNIFORM_ORDERS, Action.READ) ||
        checkPermission(authorities, PermissionResource.UNIFORM_ORDER_STOCK, Action.READ) ||
        checkPermission(authorities, PermissionResource.UNIFORM_RELEASE, Action.READ) ||
        checkPermission(authorities, PermissionResource.REPORT, Action.READ),
      items: [
        {
          label: 'Hồ sơ nhân viên',
          key: 'employeesProfile',
          isPermitted:
            checkPermission(authorities, PermissionResource.EMPLOYEE, Action.READ) ||
            checkPermission(authorities, PermissionResource.WORKSPACE, Action.READ),
          items: [
            {
              label: 'Danh sách NV',
              to: PATH.EMPLOYEES,
              key: PATH.EMPLOYEES,
              isPermitted: checkPermission(authorities, PermissionResource.EMPLOYEE, Action.READ),
            },
            {
              label: 'Văn phòng/Nhà máy',
              to: PATH.OFFICES,
              key: PATH.OFFICES,
              isPermitted: checkPermission(authorities, PermissionResource.WORKSPACE, Action.READ),
            },
          ],
        },
        {
          label: 'Đăng ký nghỉ chế độ',
          key: 'leaveRegister',
          to: PATH.LEAVE_REGISTER,
          isPermitted: checkPermission(authorities, PermissionResource.LEAVE_REGIME_REQUEST, Action.READ),
        },
        {
          label: 'Phép năm',
          key: 'annualLeave',
          to: PATH.ANNUAL_LEAVE,
          isPermitted: checkPermission(authorities, PermissionResource.ANNUAL_LEAVE, Action.READ),
        },
        {
          label: 'Tuyển dụng',
          key: 'recruitment',
          isPermitted:
            checkPermission(authorities, PermissionResource.RECRUITMENT_REQUEST, Action.READ) ||
            checkPermission(authorities, PermissionResource.INTERVIEW_SCHEDULE, Action.READ),
          items: [
            {
              label: 'Yêu cầu tuyển dụng',
              to: PATH.RECRUITMENT,
              key: PATH.RECRUITMENT,
              isPermitted: checkPermission(authorities, PermissionResource.RECRUITMENT_REQUEST, Action.READ),
            },
            {
              label: 'Danh sách ứng viên',
              to: PATH.RECRUITMENT_CANDIDATES,
              key: PATH.RECRUITMENT_CANDIDATES,
              isPermitted: checkPermission(authorities, PermissionResource.INTERVIEW_SCHEDULE, Action.READ),
            },
          ],
        },
        {
          label: 'Công văn',
          key: 'documentary',
          to: PATH.DOCUMENTARY,
          isPermitted: checkPermission(authorities, PermissionResource.DOCUMENTARY, Action.READ),
        },
        {
          label: 'Đồng phục',
          key: 'uniform',
          isPermitted:
            checkPermission(authorities, PermissionResource.UNIFORM_ORDER_STOCK, Action.READ) ||
            checkPermission(authorities, PermissionResource.UNIFORM_ORDERS, Action.READ) ||
            checkPermission(authorities, PermissionResource.UNIFORM_RELEASE, Action.READ),
          items: [
            {
              label: 'Danh sách đồng phục',
              to: PATH.UNIFORM,
              key: PATH.UNIFORM,
              isPermitted: checkPermission(authorities, PermissionResource.UNIFORM_ORDER_STOCK, Action.READ),
            },
            {
              label: 'Đơn mua đồng phục',
              to: PATH.UNIFORM_ORDERS,
              key: PATH.UNIFORM_ORDERS,
              isPermitted: checkPermission(authorities, PermissionResource.UNIFORM_ORDERS, Action.READ),
            },
            {
              label: 'Quản lý kho',
              to: PATH.UNIFORM_EXPORTS,
              key: PATH.UNIFORM_EXPORTS,
              isPermitted: checkPermission(authorities, PermissionResource.UNIFORM_RELEASE, Action.READ),
            },
          ],
        },
        {
          label: 'Báo cáo',
          key: 'report',
          isPermitted: checkPermission(authorities, PermissionResource.REPORT, Action.READ),
          items: [
            {
              label: 'Nhân viên đến hạn cấp đồng phục',
              to: PATH.REPORT_UNIFORMS_EXPIRED,
              key: PATH.REPORT_UNIFORMS_EXPIRED,
              isPermitted: checkPermission(authorities, PermissionResource.REPORT, Action.READ),
            },
            {
              label: 'Nhập xuất tồn đồng phục',
              to: PATH.REPORT_UNIFORMS_EXPORTS,
              key: PATH.REPORT_UNIFORMS_EXPORTS,
              isPermitted: checkPermission(authorities, PermissionResource.REPORT, Action.READ),
            },
            {
              label: 'NV sắp hết hạn HĐ',
              to: PATH.REPORT_EMPLOYEE_EXPIRING_CONTRACT,
              key: PATH.REPORT_EMPLOYEE_EXPIRING_CONTRACT,
              isPermitted: checkPermission(authorities, PermissionResource.REPORT, Action.READ),
            },
            {
              label: 'Biến động nhân sự',
              to: PATH.REPORT_HUMAN_RESOURCE_CHANGE,
              key: PATH.REPORT_HUMAN_RESOURCE_CHANGE,
              isPermitted: checkPermission(authorities, PermissionResource.REPORT, Action.READ),
            },
            {
              label: 'Số lượng NV đăng ký nghỉ chế độ',
              to: PATH.REPORT_LEAVE_REGIME,
              key: PATH.REPORT_LEAVE_REGIME,
              isPermitted: checkPermission(authorities, PermissionResource.REPORT, Action.READ),
            },
            {
              label: 'Yêu cầu tuyển dụng',
              to: PATH.REPORT_RECRUITMENT,
              key: PATH.REPORT_RECRUITMENT,
              isPermitted: checkPermission(authorities, PermissionResource.REPORT, Action.READ),
            },
          ],
        },
      ],
    },

    {
      key: 'logistics',
      icon: <img src="content/images/vuesax/bulk/box.svg" alt="box" style={{ width: 20, height: 20 }} />,
      selectedIcon: <img src="content/images/vuesax/bulk/box-selected.svg" alt="box-selected" style={{ width: 20, height: 20 }} />,
      label: 'Logistics',
      isPermitted: true,
      items: [
        {
          label: 'Đề xuất vật tư',
          to: PATH.MATERIAL_PROPOSAL,
          key: PATH.MATERIAL_PROPOSAL,
          isPermitted: false,
        },
        {
          label: 'Hoá đơn đầu vào',
          to: PATH.INCOMING_INVOICE,
          key: PATH.INCOMING_INVOICE,
          isPermitted: true,
        },
        {
          label: 'Quản lý nhà cung cấp',
          key: 'supplier-header',
          isPermitted: checkPermission(authorities, PermissionResource.PERMISSION, Action.READ),
          items: [
            {
              label: 'Danh sách nhà cung cấp',
              to: PATH.SUPPLIERS,
              key: PATH.SUPPLIERS,
              isPermitted: checkPermission(authorities, PermissionResource.PERMISSION, Action.READ),
            },
            {
              label: 'Danh sách nhóm nhà cung cấp',
              to: PATH.SUPPLIERS_GROUPS,
              key: PATH.SUPPLIERS_GROUPS,
              isPermitted: checkPermission(authorities, PermissionResource.PERMISSION, Action.READ),
            },
          ],
        },
      ],
    },
    {
      key: 'voucher',
      icon: <img src="content/images/vuesax/bulk/note.svg" alt="voucher" />,
      selectedIcon: <img src="content/images/vuesax/bulk/note-selected.svg" alt="voucher" />,
      label: 'Chứng từ',
      isPermitted: false,
      items: [
        {
          label: 'Đề nghị thanh toán',
          key: 'request-payment',
          to: PATH.REQUEST_PAYMENT,
          isPermitted: true,
        },
        {
          label: 'Đề nghị tạm ứng',
          key: 'request-of-advance',
          to: PATH.REQUEST_OF_ADVANCE,
          isPermitted: true,
        },
        {
          label: 'Hoàn tạm ứng',
          key: 'advance-repayment',
          to: PATH.ADVANCE_REPAYMENT,
          isPermitted: true,
        },
      ],
    },
    {
      key: 'settings',
      icon: <img src="content/images/vuesax/bulk/setting.svg" alt="setting" style={{ width: 20, height: 20 }} />,
      selectedIcon: <img src="content/images/vuesax/bulk/setting-selected.svg" alt="user-selected" />,
      label: 'Cài đặt',
      isPermitted: checkPermission(authorities, PermissionResource.PERMISSION, Action.READ),
      items: [
        {
          label: 'Quản lý quyền',
          key: 'authorities-management',
          isPermitted: checkPermission(authorities, PermissionResource.PERMISSION, Action.READ),
          items: [
            {
              label: 'Danh sách quyền',
              to: PATH.AUTHORITIES,
              key: PATH.AUTHORITIES,
              isPermitted: checkPermission(authorities, PermissionResource.PERMISSION, Action.READ),
            },
            {
              label: 'Nhóm quyền',
              to: PATH.AUTHORITIES_GROUPS,
              key: PATH.AUTHORITIES_GROUPS,
              isPermitted: checkPermission(authorities, PermissionResource.PERMISSION, Action.READ),
            },
          ],
        },
        {
          label: 'Quản lý đơn vị',
          key: 'uom-settings',
          to: PATH.UOM,
          isPermitted: checkPermission(authorities, PermissionResource.PERMISSION, Action.READ),
          // items: [
          //   {
          //     label: 'Danh sách đơn vị',
          //     to: PATH.UOM,
          //     key: PATH.UOM,
          //   },
          //   {
          //     label: 'Nhóm đơn vị',
          //     to: PATH.UOM_GROUP,
          //     key: PATH.UOM_GROUP,
          //   },
          // ],
        },
        {
          label: 'Quản lý vật phẩm',
          key: 'items-settings-header',
          isPermitted: checkPermission(authorities, PermissionResource.PERMISSION, Action.READ),
          items: [
            {
              label: 'Danh sách vật phẩm',
              to: PATH.ITEMS,
              key: PATH.ITEMS,
              isPermitted: checkPermission(authorities, PermissionResource.PERMISSION, Action.READ),
            },
            {
              label: 'Danh sách thuộc tính',
              to: PATH.ITEM_ATTRIBUTES,
              key: PATH.ITEM_ATTRIBUTES,
              isPermitted: checkPermission(authorities, PermissionResource.PERMISSION, Action.READ),
            },
          ],
        },
        {
          label: 'Quản lý nhà cung cấp',
          key: 'supplier-header',
          isPermitted: checkPermission(authorities, PermissionResource.PERMISSION, Action.READ),
          items: [
            {
              label: 'Danh sách nhà cung cấp',
              to: PATH.SUPPLIERS,
              key: PATH.SUPPLIERS,
              isPermitted: checkPermission(authorities, PermissionResource.PERMISSION, Action.READ),
            },
            {
              label: 'Danh sách nhóm nhà cung cấp',
              to: PATH.SUPPLIERS_GROUPS,
              key: PATH.SUPPLIERS_GROUPS,
              isPermitted: checkPermission(authorities, PermissionResource.PERMISSION, Action.READ),
            },
          ],
        },
        {
          label: 'Đồng phục',
          key: 'uniform-settings',
          to: PATH.UNIFORM_SETTINGS,
          isPermitted: checkPermission(authorities, PermissionResource.PERMISSION, Action.READ),
        },
        {
          label: 'Quản lý kho',
          key: 'warehouse-settings',
          isPermitted: checkPermission(authorities, PermissionResource.PERMISSION, Action.READ),
          items: [
            {
              label: 'Danh sách kho',
              to: PATH.WAREHOUSES,
              key: PATH.WAREHOUSES,
              isPermitted: checkPermission(authorities, PermissionResource.PERMISSION, Action.READ),
            },
            {
              label: 'Loại kho',
              to: PATH.WAREHOUSE_TYPES,
              key: PATH.WAREHOUSE_TYPES,
              isPermitted: checkPermission(authorities, PermissionResource.PERMISSION, Action.READ),
            },
          ],
        },
      ],
    },
  ];

  return items;
};

export const useToggleMenu = (defaultSelectedKeys: string[]) => {
  const [selectedKeys, setSelectedKeys] = useState<string[]>(defaultSelectedKeys ?? []);

  const toggleSelectedKeys = (key: string) => {
    if (selectedKeys.includes(key)) {
      setSelectedKeys(prev => prev.filter(item => item !== key));
    } else {
      setSelectedKeys(prev => [...prev, key]);
    }
  };

  return { toggleSelectedKeys, selectedKeys, setSelectedKeys };
};
