import { Nav } from 'reactstrap';
import React, { useState } from 'react';

import './menus-left.scss';
import Flex from 'app/components/flex/flex';
import MenuItem from '../menu-item/menu-item';
import { Brand } from '../header/header-components';
import { ISubMenuItem } from '../menu-item/sub-menu-item';

export type Menu = {
  key: string;
  icon: React.ReactNode;
  selectedIcon: React.ReactNode;
  label: React.ReactNode;
  items: ISubMenuItem[];
  isPermitted?: boolean;
};

interface IMenuProps {
  // defaultSelectedKeys?: string[];
  items?: Menu[];
}

const MenuLeft = (props: IMenuProps) => {
  const {
    items,
    // defaultSelectedKeys
  } = props;

  const [selectedKey, setSelectedKey] = useState<string>('');

  // const { toggleSelectedKeys, selectedKeys } = useToggleMenu(defaultSelectedKeys);

  const handleSelectedKey = (key: string) => {
    if (key === selectedKey) setSelectedKey('')
    else setSelectedKey(key)
  }

  return (
    <Nav id="menus-left" vertical>
      <Brand />

      <Flex direction="column" gap={12} className="menus-left-container">
        {items
          ?.filter(item => item?.isPermitted)
          ?.map(item => (
            <MenuItem
              onClick={() => handleSelectedKey(item.key)}
              key={item.key}
              id={item.key}
              icon={selectedKey === item?.key ? item.selectedIcon : item.icon}
              items={item.items}
              label={item.label}
              selectedKeys={selectedKey}
            />
          ))
        }
      </Flex>
    </Nav>
  );
};

export default MenuLeft;
