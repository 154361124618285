import './index.scss';
import React from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { login } from 'app/shared/reducers/authentication';
import { Translate } from 'react-jhipster';
import { Button, Alert, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import Flex from 'app/components/flex/flex';
import Form from 'app/components/form/form';
import FormInput from 'app/components/form/form-input';
import { LoginSchema, loginSchema } from 'app/validation/auth.validation';
import { zodResolver } from '@hookform/resolvers/zod';
import FormInputPassword from 'app/components/form/form-input-password';
import AuthContainer from 'app/shared/layout/auth/auth';

export const Login = () => {
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const loginError = useAppSelector(state => state.authentication.loginError);

  const navigate = useNavigate();
  const pageLocation = useLocation();
  const { control, handleSubmit } = useForm<LoginSchema>({
    resolver: zodResolver(loginSchema),
  });

  const handleLogin = (username, password, rememberMe = false) => dispatch(login(username, password, rememberMe));

  const { from } = pageLocation.state || { from: { pathname: '/', search: pageLocation.search } };
  if (isAuthenticated) {
    return <Navigate to={from} replace />;
  }

  const onSubmit: SubmitHandler<LoginSchema> = data => {
    handleLogin(data.username, data.password);
  };

  return (
    <AuthContainer label="Đăng nhập">
      <Form<LoginSchema> onSubmit={handleSubmit(onSubmit)} className="auth-form">
        <Row>
          <Col md="12">
            {loginError ? (
              <Alert color="danger" data-cy="loginError">
                <Translate contentKey="login.messages.error.authentication">
                  <strong>Đăng nhập thất bại!</strong> Vui lòng kiểm tra lại thông tin đăng nhập.
                </Translate>
              </Alert>
            ) : null}
          </Col>
          <Col md="12">
            <Flex direction="column" gap={12}>
              <FormInput control={control} name="username" label={'Tên NV'} placeholder={'Tên NV'} autoFocus />
              <Flex direction="column">
                <FormInputPassword control={control} name="password" type="password" label={'Mật khẩu'} placeholder={'Mật khẩu'} />
                <Link to={'/account/reset/request'} className="forget-password-link">
                  Quên mật khẩu
                </Link>
              </Flex>
            </Flex>
          </Col>
        </Row>
        <div className="mt-1">&nbsp;</div>

        <Flex direction="column" gap={18}>
          <Button color="primary" type="submit" data-cy="submit">
            Đăng nhập
          </Button>
          <Button color="primary" onClick={() => navigate('/account/register')} tabIndex={1}>
            Đăng ký
          </Button>
        </Flex>
      </Form>
    </AuthContainer>
  );
};

export default Login;
