import { useToggleMenu } from 'app/hooks/use-menu';
import './menu-item.scss';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ChevronDown, ChevronUp } from './menu-item';
import SubMenuChildItem from './sub-menu-child-item';
import Flex from 'app/components/flex/flex';

export interface ISubMenuItem {
  label: string;
  to?: string;
  key?: string;
  items?: ISubMenuItem[];
  id?: string;
  isPermitted?: boolean;
}

const SubMenuItem = (props: ISubMenuItem) => {
  const { label, to, items, id, isPermitted } = props;

  const { toggleSelectedKeys, selectedKeys } = useToggleMenu([]);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const selected = selectedKeys?.includes(id);

  return (
    <>
      {isPermitted !== undefined && isPermitted && (
        <div
          className="sub-menu-item-container"
          key={id}
          onClick={e => {
            e.stopPropagation();
            toggleSelectedKeys(id);
            to && navigate(to);
          }}
        >
          {/* SUB-MENU ITEMS */}
          <Flex justify='start' align='center'>
            <span className={`dot ${pathname === to ? 'active' : ''}`} />
            <div className={`sub-menu-item ${pathname === to ? 'active' : ''}`}>
              <div className="label">{label}</div>
              {items?.length && (
                <>
                  {selectedKeys.includes(id) ? (
                    <ChevronUp className="sub-menu-item-arrow" />
                  ) : (
                    <ChevronDown className="sub-menu-item-arrow" />
                  )}{' '}
                </>
              )}
            </div>
          </Flex>
          {/* DROPDOWN SUBMENU CHILD ITEMS */}
          {items?.length ? (
            <div className={`sub-menu sub-menu-child ${selected ? 'sub-menu-selected' : ''}`}>
              {items?.map(item => <SubMenuChildItem key={item.to} to={item.to} label={item.label} isPermitted={item.isPermitted} />)}
            </div>
          ) : null}
        </div>
      )}
    </>
  );
};

export default SubMenuItem;
