import Modal from 'app/components/modal/modal';
import { Typography } from 'app/components/typography/typography';
import { FORM } from 'app/shared/model/enumerations/form.model';
import React from 'react';
import { useIsMutating } from '@tanstack/react-query';
import { MUTATION_KEY } from 'app/constants/query-key';
import WarehouseTypeForm from '../components/warehouse-type-form';

const { UPDATE_WAREHOUSE_TYPE } = MUTATION_KEY;

interface IWarehouseTypeUpdateModals {
  isOpen: boolean;
  toggle: () => void;
  toggleSuccess: () => void;
  selectedRecord: string | null;
  setSelectedRecord: (value: string | null) => void;
}

const WarehouseTypeUpdateModals = (props: IWarehouseTypeUpdateModals) => {
  const { isOpen, toggle, toggleSuccess, selectedRecord, setSelectedRecord } = props;

  const isUpdating = useIsMutating({
    mutationKey: [UPDATE_WAREHOUSE_TYPE],
  });

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="modals-create-warehouse-type"
      okText="Cập nhật"
      okSubmitForm={FORM.WAREHOUSE_TYPE}
      disabledOk={!!isUpdating}
    >
      <Typography level={3}>Cập nhật loại kho</Typography>
      <WarehouseTypeForm
        type="update"
        toggle={toggle}
        toggleSuccess={toggleSuccess}
        selectedRecord={selectedRecord}
        setSelectedRecord={setSelectedRecord}
      />
    </Modal>
  );
};

export default WarehouseTypeUpdateModals;
