export enum PermissionResource {
  TIME_KEEPING = 'TIME_KEEPING',
  GROUP = 'GROUP',
  COMPANY = 'COMPANY',
  ANNUAL_LEAVE = 'ANNUAL_LEAVE',
  EMPLOYEE = 'EMPLOYEE',
  EMPLOYEE_PROFILE = 'EMPLOYEE_PROFILE',
  PERSONAL_MONTHLY_TIMESHEET = 'PERSONAL_MONTHLY_TIMESHEET',
  LEAVE_REQUEST = 'LEAVE_REQUEST',
  INTERVIEW_SCHEDULE = 'INTERVIEW_SCHEDULE',
  RECRUITMENT_REQUEST = 'RECRUITMENT_REQUEST',
  TIME_KEEPING_VIOLATION = 'TIME_KEEPING_VIOLATION',
  TIME_KEEPING_EXPLANATION = 'TIME_KEEPING_EXPLANATION',
  EXPLANATION_REVIEW = 'EXPLANATION_REVIEW',
  WORK_ITEM = 'WORK_ITEM',
  PRODUCTION_STANDARD = 'PRODUCTION_STANDARD',
  RECEIVE_MATERIAL_CHECKLIST = 'RECEIVE_MATERIAL_CHECKLIST',
  MACHINE_OPERATION_CHECKLIST = 'MACHINE_OPERATION_CHECKLIST',
  ADDITIVE_MATERIAL_CHECKLIST = 'ADDITIVE_MATERIAL_CHECKLIST',
  QUALITY_CHECK_SAMPLE = 'QUALITY_CHECK_SAMPLE',
  MANUFACTURE_ORDER = 'MANUFACTURE_ORDER',
  SAMPLE_DISPOSAL = 'SAMPLE_DISPOSAL',
  WORK_ORDER = 'WORK_ORDER',
  STEAMING_PROCESS_CHECKLIST = 'STEAMING_PROCESS_CHECKLIST',
  METAL_DETECTION_CHECKLIST = 'METAL_DETECTION_CHECKLIST',
  MIXING_REPORT_CHECKLIST = 'MIXING_REPORT_CHECKLIST',
  WORK_CENTER = 'WORK_CENTER',
  PRODUCT_MAINTAIN = 'PRODUCT_MAINTAIN',
  PRODUCT_ROUTING = 'PRODUCT_ROUTING',
  PRODUCT_PACKAGE = 'PRODUCT_PACKAGE',
  CONTRACT = 'CONTRACT',
  CUSTOMER = 'CUSTOMER',
  ORDER = 'ORDER',
  PURCHASE_REQUEST = 'PURCHASE_REQUEST',
  QUOTATION = 'QUOTATION',
  LEAVE_REGIME_REQUEST = 'LEAVE_REGIME_REQUEST',
  DOCUMENTARY = 'DOCUMENTARY',
  UNIFORM = 'UNIFORM',
  UNIFORM_ORDERS = 'UNIFORM_ORDER',
  UNIFORM_RELEASE = 'UNIFORM_RELEASE',
  PERMISSION = 'PERMISSION',
  WORKSPACE = 'WORKSPACE',
  UNIFORM_ORDER_STOCK = 'UNIFORM_ORDER_STOCK',
  REPORT = 'REPORT',
  ACCOUNT = 'ACCOUNT',
}

export enum Role {
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_USER = 'ROLE_USER',
  ROLE_DIRECTOR = 'ROLE_DIRECTOR',
  ROLE_DEPARTMENT_MANAGER = 'ROLE_DEPARTMENT_MANAGER',
  ROLE_WORKER = 'ROLE_WORKER',
  ROLE_STAFF = 'ROLE_STAFF',
}

export enum Action {
  CREATE = 'CREATE',
  READ = 'READ',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  REVIEW = 'REVIEW',
  CREATE_MANY = 'CREATE_MANY',
  EXPORT = 'EXPORT',
}
