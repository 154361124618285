export enum FORM {
  UPDATE_TIMESHEET = 'update-timesheet',
  APPROVE_TIMESHEET = 'approve-timesheet',
  REJECT_TIMESHEET = 'reject-timesheet',
  REJECT_EXPLANATION = 'reject-explanation',
  PRODUCTION_STANDARD = 'production-standard',
  LEAVE_REQUEST = 'leave-request',
  REJECT_LEAVE_REQUEST = 'reject-leave-request',
  PRODUCTION_PROCESS = 'production-process',
  PRODUCTION_COMMAND_ORDERS = 'production-command-orders',
  PRODUCTION_COMMAND_STANDARD = 'production-command-standard',
  CHECK_THE_POWDER = 'check-the-powder',
  PRODUCTION_WORK_CENTERS = 'production-work-centers',
  PRODUCTION_ROUTINGS = 'production-routings',
  PRODUCTION_MAINTENANCE = 'production-maintenance',
  PRODUCTION_PACKAGES = 'production-packages',
  MATERIAL_RECEIPT_MONITORING = 'material-receipt-monitoring',
  SELECTING_ADDING_ADDITIVES = 'selecting-adding-additives',
  MACHINE_OPERATION_MONITORING = 'machine-operation-monitoring',
  MONITORING_STEAMING_DRYING = 'monitoring-steaming-drying',
  MAGNET_MESH_TEST = 'magnet-mesh-test',
  REPORT_MIXING_FISHMEAL = 'report-mixing-fishmeal',
  CREATE_TESTING_TEMPLATE = 'create-testing-template',
  UPDATE_TESTING_TEMPLATE = 'update-testing-template',
  CANCEL_TESTING_TEMPLATE = 'cancel-testing-template',
  REJECT_CANCEL_TESTING = 'reject-cancel-testing',
  EMPLOYEES_CREATE = 'employees-create',
  CUSTOMER = 'customer',
  CUSTOMER_TRANSFER = 'customer-transfer',
  PRICE_LIST = 'price-list',
  REJECT_LIQUID = 'reject-liquid',
  ORDER = 'order',
  PURCHASE = 'purchase',
  ANNUAL_LEAVE = 'annual-leave',
  OFFICES = 'offices',
  LEAVE_REGISTER = 'leave-register',
  LEAVE_TRACKING = 'leave-tracking',
  ORDER_REVIEW = 'order-review',
  REJECT_QUOTATION = 'reject-quotation',
  RECRUITMENT = 'recruitment',
  RECRUITMENT_RENEW = 'recruitment-renew',
  VOUCHER_PROPOSE = 'voucher-propose',
  CONFIRM_LEAVE = 'confirm-leave',
  MATERIAL = 'material',
  REQUEST_PAYMENT = 'request-payment',
  REQUEST_OF_ADVANCE = 'request-of-advance',
  ADVANCE_REPAYMENT = 'advance-repayment',
  UNIFORM = 'uniform',
  UOM = 'uom',
  WAREHOUSE = 'warehouse',
  WAREHOUSE_TYPE = 'warehouse-type',
  GROUP = 'group',
  INCOMING_INVOICE = 'incoming-invoice',
  ITEM = 'item',
  LOGISTICS_MATERIAL_PROPOSAL = 'logistics-material-proposal',
  LOGISTICS_MATERIAL_PROPOSAL_APPROVE = 'LOGISTICS_MATERIAL_PROPOSAL_APPROVE',
  CREATE_ACCOUNT = 'create-account',
  SUPPLIER = 'supplier',
}

export type FormType = `${FORM}`;
